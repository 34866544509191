<template>
  <div class="work_detail">
    <van-nav-bar
        title="工单详情"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <scroll class="detail" :onLoadMore="onLoadMore">
      <div class="work_content_item">
        <div class="work_number">
          <div class="number">
            <div>工单编号</div>
            <div>{{ workOrderInfo.woCode }}</div>
          </div>
          <div class="work_state">
            <span class="work_state_did" v-if="workOrderInfo.woState == 0"
            >未开始</span
            >
            <span class="work_state_in" v-if="workOrderInfo.woState == 1"
            >执行中</span
            >
            <span class="work_state_end" v-if="workOrderInfo.woState == 2"
            >已结束</span
            >
            <span class="work_state_cancel" v-if="workOrderInfo.woState == 3"
            >已取消</span
            >
          </div>
        </div>
        <div class="work_name">
          <div class="name">产品名称:</div>
          <div>{{ workOrderInfo.productName }}</div>
        </div>
        <div class="work_progress">
          <div class="work_progress_name">进度:</div>
          <div class="work_progress_mun">
            {{
              workOrderInfo.goodProductCounts == null
                  ? "0"
                  : workOrderInfo.goodProductCounts
            }}
            / {{ workOrderInfo.planCounts }}
          </div>
        </div>
        <div class="work_time">
          <div class="time_name">计划时间:</div>
          <div class="time">
            {{ workOrderInfo.planStartTime | formDate }}~{{
              workOrderInfo.planEndTime | formDate
            }}
          </div>
        </div>
        <!-- <div
                    class="icon icon-xiajiantou"
                    @click="changeDetailAll"
                    v-show="show"
                ></div>
                <div class="detail_all" v-show="!show">
                    <div class="line"></div>
                    <div class="all_item">备注：-</div>
                    <div class="all_item">流次：-</div>
                    <div class="all_item">类型：-</div>
                    <div
                        class="icon icon-shangjiantou"
                        @click="changeDetailAll"
                    ></div>
                </div> -->
      </div>
      <div class="production">生产进度</div>
      <div
          class="production_item"
          v-for="(item, index) in procedureList"
          :key="item.id"
      >
        <div class="work_item_header">
          <div class="work_item_l">
            <div class="work_item_index">{{ index + 1 }}</div>
            <div class="work_item_name">{{ item.wpName }}</div>
            <!-- <div class="work_item_state">延期</div> -->
          </div>
          <div class="work_item_r">
            <span class="work_state_did" v-if="item.state == 0">未开始</span>
            <span class="work_state_in" v-if="item.state == 1">执行中</span>
            <span class="work_state_end" v-if="item.state == 2">已结束</span>
            <span class="work_state_cancel" v-if="item.state == 3">已取消</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="work_plan">
          <div class="work_plan_num comm">
            <div class="name">计划数</div>
            <div class="num">{{ item.planCounts }}</div>
          </div>
          <div class="work_product comm">
            <div class="name">良品</div>
            <div class="num">
              {{
                item.goodProductCounts == null ? "0" : item.goodProductCounts
              }}
            </div>
          </div>
          <div class="work_no_product comm">
            <div class="name">不良品数</div>
            <div class="num">
              {{ item.badProductCounts == null ? "0" : item.badProductCounts }}
            </div>
          </div>
          <div
              @click="productionWork(item)"
              class="report_work comm"
              v-show="workOrderInfo.woState == 1"
          >
            <div class="icon icon-chuangjiangongdan"></div>
            <div class="report_work_btn">报工</div>
          </div>
        </div>
      </div>
    </scroll>
    <div class="defailt_floor" v-if="workOrderInfo.woState == 0">
      <div class="defailt_floor_strat">
        <div class="strat" @click="stratWork">开始</div>
      </div>
    </div>
    <div class="defailt_floor" v-else-if="workOrderInfo.woState == 1">
      <div class="defailt_floor_end">
        <div class="floor_item" @click="onCancel">撤回</div>
        <div class="floor_item over" @click="onOver">结束</div>
      </div>
    </div>
    <div class="no_display" v-else></div>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import {ChangePageTitle} from "../../common/js/upTitle";
import {
  getWorkDetailList,
  getProcedureList,
  upDateWorkOrder,
} from "@/api/work.js";
import {mapGetters, mapActions} from "vuex";

import {Dialog} from 'vant';

export default {
  computed: {
    ...mapGetters(["workId", "departmentCode"]),
  },
  data() {
    return {
      // show: true,
      workOrderInfo: [], //工单详情
      procedureList: [], //工单工序
    };
  },
  created() {
    ChangePageTitle("工单详情");
    console.log(this["workId"],'我是this["workId"]')
    this.getWorkDetailList(this["workId"]);
  },
  methods: {
    ...mapActions(["work/getShareWorkId", "work/closeWorkId", 'work/getShareWorkIngId']),
    // changeDetailAll() {
    //     this.show = !this.show;
    // },
    onClickLeft() {
      this.$router.replace({path: "/"});
      this["work/closeWorkId"]();
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    async getWorkDetailList(id) {
      try {
       
        const res = await getWorkDetailList(id);
        
        this.workOrderInfo = res;
        const params = {
          departmentCode: this["departmentCode"],
          woCode: res.woCode,
        };
        const res1 = await getProcedureList(params);
        this.procedureList = res1;
      } catch (err) {
        console.log(err);
      }
    },
    async onCancel() {
      const params = {
        id: this.workOrderInfo.id,
        departmentCode: this.workOrderInfo.departmentCode,
        productName: this.workOrderInfo.productName,
        productUnit: this.workOrderInfo.productUnit,
        productCode: this.workOrderInfo.productCode,
        woCode: this.workOrderInfo.woCode,
        woState: 0,
      };
      await upDateWorkOrder(params);
      this.$toast("任务已撤回");
      this.getWorkDetailList(this["workId"]);
      this.$router.push("/index");
      // Dialog.confirm({
      //   title: '标题',
      //   message: '弹窗内容',
      // })
      //     .then(async () => {
      //       await upDateWorkOrder(params);
      //       this.$toast("任务已撤回");
      //       this.getWorkDetailList(this["workId"]);
      //     })
      //     .catch(() => {
      //       this.$router.push("/index");
      //     });
    },
    async onOver() {
      Dialog.confirm({
        title: '提示',
        message:
          '是否确认结束',
      }).then(() => {
          console.log('确认 :>> ');
          this.onOverTO();
        })
        .catch(() => {
          console.log('取消 :>> ');
          return;
        });
    },
    async onOverTO(){
      const params = {
        id: this.workOrderInfo.id,
        departmentCode: this.workOrderInfo.departmentCode,
        productName: this.workOrderInfo.productName,
        productUnit: this.workOrderInfo.productUnit,
        productCode: this.workOrderInfo.productCode,
        woCode: this.workOrderInfo.woCode,
        woState: 2,
      };
      await upDateWorkOrder(params);
      this.$toast("任务已结束");
      this.$router.push("/index");
    },
    async stratWork() {
      const params = {
        id: this.workOrderInfo.id,
        departmentCode: this.workOrderInfo.departmentCode,
        productName: this.workOrderInfo.productName,
        productUnit: this.workOrderInfo.productUnit,
        productCode: this.workOrderInfo.productCode,
        woCode: this.workOrderInfo.woCode,
        woState: 1,
      };
      await upDateWorkOrder(params);
      this.$toast("任务已开始");
      this.getWorkDetailList(this["workId"]);

      this.$router.push("/index");
    },
    productionWork(i) {
      this['work/getShareWorkIngId'](i.id)
      this.$router.push({
        name: "/reportWork",
      });
    },
  },
  filters: {
    formDate(val) {
      if (val != null) {
        var d = val.replace(/-/g, "/");
        var date = new Date(d);
        let year = date.getFullYear(); //年
        let month = date.getMonth() + 1; //月
        let day = date.getDate(); //日
        if (month >= 1 && month <= 9) {
          month = `0${month}`;
        }
        if (day >= 1 && day <= 9) {
          day = `0${day}`;
        }
        return `${year}-${month}-${day}`;
      }
    },
  },
  components: {
    scroll,
    [Dialog.name]: Dialog
  },
};
</script>
<style lang="less" scoped>
.work_detail {
  width: 100vw;
  background-color: #fafafa;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;

  .detail {
    height: calc(100vh - 180px);

    .work_content_item {
      width: 95%;
      margin: auto;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 10px;
      margin-top: 40px;
      padding: 30px 16px;

      .work_number {
        display: flex;
        justify-content: space-between;

        .number {
          display: flex;
          justify-content: start;
          align-items: center;

          div:nth-child(1) {
            text-align: center;
            line-height: 1.2;
            font-size: 22px;
            color: #1989fa;
            border: 1px solid #1989fa;
            padding: 2px;
          }

          div:nth-child(2) {
            color: black;
            font-size: 36px;
            font-weight: 700;
            margin-left: 20px;
          }
        }

        .work_state {
          font-size: 30px;
          text-align: center;
          line-height: 1;

          .work_state_in {
            color: blue;
          }

          .work_state_end {
            color: black;
          }

          .work_state_cancel {
            color: red;
          }
        }
      }

      .work_name {
        margin-top: 20px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 30px;

        div:nth-child(2) {
          margin-left: 10px;
        }
      }

      .work_progress {
        margin-top: 20px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 30px;

        div:nth-child(2) {
          margin-left: 10px;
        }
      }

      .work_time {
        margin-top: 20px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 30px;

        div:nth-child(2) {
          margin-left: 10px;
        }
      }

      .icon {
        text-align: center;
        font-size: 0.125rem;
        margin-top: 10px;
        transform: scale(0.5);
        color: #999;
      }

      .detail_all {
        margin-top: 10px;

        .line {
          border-top: 1px dashed #000;
        }

        .all_item {
          margin-top: 10px;
          font-size: 14px;
        }

        .icon {
          text-align: center;
          font-size: 0.275rem;
          margin-top: 10px;
          transform: scale(1.3);
          color: #999;
        }
      }
    }

    .production {
      width: 95%;
      margin: auto;
      margin-top: 30px;
      font-size: 36px;
    }

    .production_item {
      width: 95%;
      margin: auto;
      margin-top: 40px;
      background-color: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 24px;

      .work_item_header {
        display: flex;
        height: 40px;
        justify-content: space-between;

        .work_item_l {
          display: flex;
          text-align: center;
          line-height: 40px;
          justify-content: start;

          .work_item_index {
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            background-color: blue;
            color: #fff;
            border-radius: 50%;
          }

          .work_item_name {
            margin-left: 20px;
            font-size: 30px;
            color: black;
          }

          .work_item_state {
            color: red;
            padding: 5px;
            font-size: 30px;
            border: 1px solid red;
            box-sizing: border-box;
            font-size: 12px;
            line-height: 10px;
          }
        }

        .work_item_r {
          font-size: 30px;

          .work_state_in {
            color: blue;
          }

          .work_state_end {
            color: black;
          }

          .work_state_cancel {
            color: red;
          }
        }
      }

      .line {
        border-top: 1px dashed #000;
        margin-top: 15px;
      }

      .work_plan {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .comm {
          flex: 1;
        }

        .work_plan_num {
          font-size: 30px;

          .name {
            text-align: center;
          }

          .num {
            text-align: center;
            margin-top: 15px;
            color: black;
          }
        }

        .work_product {
          font-size: 30px;

          .name {
            text-align: center;
          }

          .num {
            text-align: center;
            margin-top: 15px;
            color: black;
          }
        }

        .work_no_product {
          font-size: 30px;

          .name {
            text-align: center;
          }

          .num {
            text-align: center;
            margin-top: 15px;
            color: red;
          }
        }

        .report_work {
          padding: 10px;
          background-color: blue;
          display: flex;
          font-size: 34px;
          border-radius: 50px;
          justify-content: center;
          align-items: center;
          color: #fff;

          .icon {
            font-size: 30px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .defailt_floor {
    height: 100px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 10px;
    box-sizing: border-box;
    z-index: 100;
    background-color: #fff;

    .defailt_floor_end {
      font-size: 30px;
      display: flex;

      .floor_item {
        flex: 1;
        color: blue;
        text-align: center;
        line-height: 80px;
      }

      .over {
        width: 100%;
        height: 100%;
        background-color: red;
        color: #fff;
        border-radius: 10px;
      }
    }

    .defailt_floor_strat {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      font-size: 30px;

      .strat {
        width: 40%;
        height: 100%;
        background-color: blue;
        color: #fff;
        border-radius: 5px;
        text-align: center;
        line-height: 80px;
      }
    }
  }

  .no_display {
    display: none;
  }
}
</style>
